'use strict';
let cart = () => {
	const templates = {
		'item': $.templates('#template-cart-item'),
		'totalRow': $.templates('#template-cart-total'),
	}

	const isSmallMobileDetected = () => window.matchMedia('screen and (max-width: 768px)').matches;

	const recaclulateTotal = (items) => {
		let total = 0;
		items.forEach(item => {
			total += (item.unitPrice*item.quantity)/100;
		});

		return total;
	}

	const emptiedCart = (state) => {
		if (state) {
			$('.cartTotalInfo').hide();
			$('.emptyCart').show();
			$('.createCheckout').addClass('disabled').prop('disabled', true)
			$('.cartItemCount').fadeOut();
		} else {
			$('.cartTotalInfo').show();
			$('.emptyCart').hide();
			$('.createCheckout').removeClass('disabled').prop('disabled', false)
			$('.cartItemCount').fadeIn();
		}
	}

	const initializeSwipeActions = ($row) => {

		let touchStartX;
		let touchstartY;
		let touchendX;
		let touchendY;

		function handleGesture() {

			//swipe left
			if (touchendX <= touchStartX) {
			}
			
			// swipe right
			if (touchendX >= touchStartX) {
				if ($row.hasClass('delete-pending')) {
					$row.fadeOut();
					$row.find('.btn[data-mode="remove"]').click();
				}
			}
			
			// swipe up
			if (touchendY <= touchstartY) {
			}

			// swipe down
			if (touchendY >= touchstartY) {
			}
			
			// tap
			if (touchendY === touchstartY) {
				
			}
		}

		$row.on('touchstart', function(event) {
			//$row.addClass('swiping');
			touchStartX = event.changedTouches[0].clientX;
			touchstartY = event.changedTouches[0].clientY;
		});

		$row.on('touchmove', function(event) {

			let $moveable = $row.find('.row_contents')

			// the distance between where the user is now and when the user
			let deltaX = event.changedTouches[0].clientX - touchStartX;
			

			// how much has the delta moved?
			let secondDelta = $row.data('delta') ? Math.abs(deltaX-parseInt($row.data('delta'))) : Math.abs(deltaX);

			if (deltaX < 0) {
				return;
			}

			if (secondDelta > 20) {
				$row.addClass('swiping');
			}

			if (deltaX > 68) {
				if (!$row.hasClass('delete-pending')) {
					$row.addClass('delete-pending');
				}

			} else {
				$row.removeClass('delete-pending')
			}

			// minimum distance before swipe is recognized
			if (secondDelta < 20) {
				return;
			}

			// maximum scroll position
			if (deltaX > 115 || deltaX < -115) {
				return;
			}

			$row.data('delta', deltaX);
			$moveable.css('left', deltaX);

		});
		
		$row.on('touchend', function(event) {
			$row.find('.row_contents').css('left', 0);

			$row.data('delta', null);
			$row.removeClass('swiping');
			
			touchendX = event.changedTouches[0].clientX;
			touchendY = event.changedTouches[0].clientY;
			handleGesture();
		}); 

	}
	const initializeItemRow = ($item) => {
		let SKU = $item.data('sku');
		let product = $item.data('product');

		$item.find('.quantityChange').on('click', function() {
			const $this = $(this);
			const $row = $this.parents('.cart_item');
			const placementID = $row.data('cartPlacementId');
			const mode = $this.data('mode');
			const rentalFeeScheduleID = $row.data('rentalScheduleId');

			$.ajax({
				url: `/store/cart/${mode}/`,
				method: 'POST',
				data: {
					placementID,
					rentalFeeScheduleID,
				},
				success: (data) => {
					if (data.error) {
						alert(data.message);
						return;
					}
					if (data.item && data.item.quantity) {
						let $newItemLine = $(templates.item.render(data.item));
						initializeItemRow($newItemLine);

						$row.replaceWith($newItemLine)
						
					} else {
						$row.fadeOut(400, null, ()=> $row.remove());
					}

					if (data.cart) {
						if (mode === 'add') {
							if (data.quantity === 0) {
								alert("We're sorry; there is not enough of that item in stock to add another to your cart.");
							} else if (data.quantity < 0) {
								// did we get rid of it completely? if so, it's completely sold out.
								if (data.cart && data.cart.items.find(x => x.SKU === SKU)) {
									alert("We're sorry, but our inventory count has decreased since you added this to your cart and we have adjusted the amount to what we can fulfill.")
								} else {
									alert("We're sorry; this item is now sold out.");
								}
							}
						} else if (mode === 'minus' && data.quantity !== 1) {
							if (data.cart && data.cart.items.find(x => x.SKU === SKU)) {
								alert("The amount of stock we have inventory for this item has decreased for this item has changed since you added it and we are adjusting the amount you have in your cart.")
							} else {
								alert("We're sorry; this item is now sold out.");
							}
						}
						let newTotal = recaclulateTotal(data.cart.items);

						$('.cart_total').replaceWith(templates.totalRow.render({total:newTotal}));
					
						emptiedCart(false);
					} else { 
						emptiedCart(true);
					}

					$('.cartItemCount').text(data.cart ? data.cart.itemCount : 0);
				}
			})
		})

		initializeSwipeActions($item);

		if (isSmallMobileDetected()) {
			$item.find('.dataRow').addClass('smallText');
		}
	}

	$('.cart_item.actual_item').each((k,v) => initializeItemRow($(v)));

	if ($('.actual_item.cart_item').length > 0) {
		emptiedCart(false);
	} else {
		emptiedCart(true);
	}

	$('.createCheckout').on('click', function () {

		const $this = $(this);
		const originalText = $this.text();

		if ($this.hasClass('disabled')) {
			return;
		}

		$this.addClass('disabled');
		$this.prop('disabled', true);
		$this.html('<i class="fa fa-spinner fa-spin"></i> Processing');

		if (!$(this).data('signedin')) {
			window.location = '/login';
			return;
		}
		$.ajax('/store/checkout', {
			method:'post',
			success: (data) =>{
				if (data.error) {

					$this.removeClass('disabled').prop('disabled', false).text(originalText);
					return alert(data.message || "An error has occurred");
				}
				
				if (data.checkout_id) {
					stripe.redirectToCheckout({sessionId: data.checkout_id});
				}
			}
		})
	});
	
	$('.addToCart').on('click', function(e) { 
		let $btn = $(this);
		let url = '';

		if ($btn.hasClass('disabled') || !$btn.data('sku')) {
			return;
		}

		$btn.addClass('disabled').prop('disabled', true);

		if ($btn.data('isGiftcard')) {
			let $fields = $btn.data('giftcardFields');
			let validateFx = $btn.data('giftcardValidateFx');
			let errors = 0;

			$fields.each((k,v) => {
				let $field = $(v);
				let validationResponse = validateFx($field);
				if (validationResponse.length > 0) {
					errors++;
				}
			});

			if (errors > 0) {
				alert("Please correct the errors before adding the gift card to your cart.");
				$btn.removeClass('disabled').prop('disabled', false);
				return;
			}
		}

		let $quantity = $($btn).parents('.addCartGroup').find('input.quantity');
		let quantity = 1;

		if ($quantity.length == 1 && $quantity.val() !== "") {
			quantity = Math.abs(parseInt($quantity.val()));
		}

		const hasEntitlementOptions = $('.buyOptionGroupItem input[type="radio"]').length > 0;
		const hasEntitlementOptionSelected = $('.buyOptionGroupItem input[type="radio"]:checked').length > 0;
		const $selectedEntitlementOption = $('.buyOptionGroupItem input[type="radio"]:checked');

		if (hasEntitlementOptions && !hasEntitlementOptionSelected) {
			alert("Please select a purchasing option before adding this item to your cart.");
			$btn.removeClass('disabled').prop('disabled', false);
			return;
		}

		if ($btn.data('amount') && quantity > parseInt($btn.data('amount'))) {
			// return alert(`We cannot fulfill more than ${$btn.data('amount')} of this item at this time.`);
		}

		// if there's no option available, then its considered to be a purchase of the item

		let operation = !hasEntitlementOptions || $selectedEntitlementOption.val() === 'buy' ? 'add' : 'addRental';
		const formData = {quantity, rentalFeeScheduleID: $selectedEntitlementOption.val()};

		if ($btn.data('isGiftcard')) {
			// gift cards are handled differently
			operation = 'addGiftcard';
			
			formData.giftTag = {};
			formData.gc_options = {};

			$btn.data('giftcardFields').each((k,v) => {
				let $field = $(v);
				formData.giftTag[$field.attr('name')] = $field.val();
			});

			$btn.data('giftcardProductOptions').each((k,v) => {
				let $field = $(v);
				formData.gc_options[$field.attr('name')] = $field.val();
			});
		}

		url = `/store/cart/${operation}/`;
		formData.SKU = $btn.data('sku');

		$.ajax({
			url,
			method: 'POST',
			data: formData,
			success: (data) => {

				$btn.removeClass('disabled').prop('disabled', false);

				if (data.error === true) {
					alert(data.message || "An unknown error has occurred.");
					return;
				}

				let $newItemLine = $(templates.item.render(data.item));

				let $existingRow;

				if (data.item.entitlementType === 'buy') {
					$existingRow = $(`.cart_item[data-cart-placement-id="${data.item._id}"]`);
				} else {
					$existingRow = $(`.cart_item[data-cart-placement-id="${data.item._id}"][data-entitlement-type="rent"][data-interval="${data.rentalFeeSchedule.interval}"][data-interval-count="${data.rentalFeeSchedule.interval_count}"]`);
				}

				initializeItemRow($newItemLine);
				
				if ($existingRow.length > 0) {
					$existingRow.replaceWith($newItemLine)
				} else {
					$('.cart-modal .list_wrapper').append($newItemLine);
				}
					

				if (data.cart) {
					// did it add less than we requested? if so, let the user know!
					if (data.quantity !== quantity) {
						if (data.quantity <= 0) {
							alert("Sorry! We're sold out of this item and cannot add it to your cart.")
						} else {
							alert(`We are running low on stock for this item, so we could only add ${data.quantity} to your cart.`)
						}
					}
					let newTotal = recaclulateTotal(data.cart.items);
					$('.cart_total').replaceWith(templates.totalRow.render({total:newTotal}));

					emptiedCart(false);
				} else { 
					emptiedCart(true);
				}
				
				$quantity.val('');
				
				$('.cartItemCount').text(data.cart.itemCount);

				if ($btn.data('success-callback') && typeof $btn.data('success-callback') == "function") {
					$btn.data('success-callback')(data);
				} else {
					alert("Your selection has been added to your cart.");
				}
			}
		})


	});

};

cart();